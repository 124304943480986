<template>
    <div v-if="form">
        <vertical-form-table v-bind="{ items }" topBorderMdNone class="vertical-form-table--border">
            <template #문의유형>
                <v-select v-model="form.type" v-bind="{ ...attrs_input, loading }" dense :items="questionTypes" placeholder="문의유형" class="w-md-358px" />
            </template>
            <template #작성자>
                <v-text-field v-model="username" v-bind="{ ...attrs_input, loading }" dense class="w-md-358px" disabled/>
            </template>
            <template #제목>
                <v-text-field v-model="form.subject" v-bind="{ ...attrs_input, loading }" dense />
            </template>
        </vertical-form-table>
        <div class="py-12px py-md-20px">
            <div class="txt txt--dark font-weight-medium pb-8px d-md-none">내용</div>
            <v-textarea v-model="form.content" v-bind="{ ...attrs_input, loading }" rows="14" />
        </div>
        <v-divider class="border-dark d-none d-md-block" style="border-width: 2px 0 0" />
        <!-- S: 첨부파일 추가(모바일) -->
        <div class="py-12px d-md-none">
            <div class="txt txt--dark font-weight-medium pb-4px">첨부파일 추가</div>
            <div class="overflow-x-auto overflow-y-hidden mr-n12px pt-4px">
                <div class="d-flex flex-nowrap pr-8px">
                    <div class="pr-4px">
                        <v-btn tile width="72" height="72px !important" color="grey lighten-5" @click="upload">
                            <i class="icon icon-image" />
                        </v-btn>
                        <input type="file" id="fileUpload" hidden multiple @change="handleFiles" accept="image/*">
                    </div>
                    <!-- 반복 -->
                    <div v-for="(preivew, index) in previews" :key="index" class="pr-4px">
                        <v-card tile class="attachment-img">
                            <v-btn fab color="primary" class="attachment-img__close"><v-icon x-small color="white" @click="close(index)">mdi-close</v-icon></v-btn>
                            <v-img :src="preivew" :aspect-ratio="1 / 1" width="72">
                                <template #placeholder>
                                    <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                </template>
                            </v-img>
                        </v-card>
                    </div>
                </div>
            </div>
        </div>
        <!-- E: 첨부파일 추가(모바일) -->

        <div class="btn-wrap mx-n12px mx-md-0px">
            <v-row justify="space-between" class="ma-0px ma-md-n4px">
                <!-- S: 첨부파일 추가(PC) -->
                <v-col cols="6" md="" class="d-none d-md-block">
                        <v-btn v-bind="{ ...btn_secondary, loading }" class="v-size--xx-large w-100 w-md-140px" @click="upload">이미지 추가</v-btn>
                        <input type="file" id="fileUpload" hidden multiple @change="handleFiles" accept="image/*">
                        <div class="pt-16px">
                            <v-row class="row--xxs">
                                <v-col cols="auto">
                                    <v-row align="center" class="row--xxs">
                                        <!-- 대표이미지 class="thumb" -->
                                        <v-col v-for="(preivew, index) in previews" :key="index" cols="auto">
                                            <v-card tile class="attachment-img">
                                                <v-btn fab color="primary" class="attachment-img__close"><v-icon x-small color="white" @click="close(index)">mdi-close</v-icon></v-btn>
                                                <v-img :src="preivew" :aspect-ratio="1 / 1" width="72">
                                                    <template #placeholder>
                                                        <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                    </template>
                                                </v-img>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                <!-- E: 첨부파일 추가(PC) -->
                <v-col cols="12" md="auto" class="pa-0px pa-md-4px">
                    <v-row class="ma-0px ma-md-n4px">
                        <v-col cols="6" md="auto" class="pa-0px pa-md-4px">
                            <v-btn v-bind="{ ...btn_primary, loading }" class="v-size--xx-large w-100 min-w-md-140px" @click="save">저장</v-btn>
                        </v-col>
                        <v-col cols="6" md="auto" class="pa-0px pa-md-4px">
                            <v-btn v-bind="{ ...btn_secondary, loading }" class="v-size--xx-large min-w-md-140px d-none d-md-block" @click="$router.go(-1)">취소</v-btn>
                            <v-btn v-bind="{ ...btn_primary3, loading }" class="v-size--xx-large w-100 d-md-none" @click="$router.go(-1)">취소</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import api from "@/api";
import { QUESTION_TYPES } from "@/assets/variables";
import { btn_primary, btn_primary3, btn_secondary, attrs_input } from "@/assets/variables";

import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

const initQuestion = (question = {}) => ({
    ...question,
    _files : question?._files || [],
    
    type: question?.type || null,
    subject: question?.subject || null,
    content: question?.content || null,
    reply: question?.reply || null,
    files: question?.files || [],
})

const items = [
    {
        term: "문의유형",
        type: "text",
        class: "w-md-358px",
    },
    {
        term: "작성자",
        type: "text",
        class: "w-md-358px",
    },
    {
        term: "제목",
        type: "text",
    },
].map((item) => ({ ...item, dense: true, outlined: true }));

export default {
    components: {
        VerticalFormTable,
    },
    data() {
        return {
            btn_primary,
            btn_primary3,
            btn_secondary,
            attrs_input,

            items,
            username: null,

            form: initQuestion(),

            previews: [],
            loading: false,
        };
    },
    computed: {
        questionTypes() {
            return Object.values(QUESTION_TYPES);
        },
        _question() {
            return this.$route.params._question;
        }
    },
    methods: {
        ...mapActions(["getUser"]),
        async init(){
            try{
                await this.getUser();
                this.username = this.$store.state.user.username;

                if(!this._question){
                    this.form = initQuestion();
                } else{
                    this.form = initQuestion((await api.v1.center.questions.get({ _id: this._question }))?.question);
                    this.previews = this.form.files.map((file)=> file.src);
                };
            } catch(error){
                this.$handleError(error);
            }
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if(!this.validate()) return;
                
                let { post, put } = api.v1.center.questions;
                let { _files, files, ...form } = this.form;
                let question = (await (this._question ? put : post)(form))?.question;

                let fileArr = [];
                await Promise.all(
                    files.map(async (data)=> {
                        if(data instanceof File) data = (await api.v1.files.post({ path:"questions", index: question._id}, data))?.file;
                        fileArr.push(data._id);
                    })
                );

                _files = _files.filter((_file) => !fileArr.includes(_file));

                if(_files) await Promise.all(
                    _files.map(async (_file)=> {
                        await api.v1.files.delete({ _id: _file });
                    })
                )

                question = (await put({ _id: question._id, _files: fileArr }))?.question;

                alert("문의가 등록되었습니다");
                this.$router.go(-1);
            } catch(error){
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        validate(){
            if (!this.form.type) throw new Error("문의유형을 선택해주세요");
            if (!this.form.subject) throw new Error("제목을 입력해주세요");
            if (!this.form.content) throw new Error("내용을 입력해주세요");

            return true;
        },

        //File
        upload() {
            document.getElementById("fileUpload").click();
        },
        createURL(files){
            this.previews = files.map((file) => URL.createObjectURL(file));
        },
        handleFiles(e){
            let files = [...e.target.files];
            if(!this.validateFile(files)) return;

            this.form.files = [...e.target.files];
            this.createURL(this.form.files);
        },
        validateFile(files){
            files.forEach((file)=> {
                if(!file.type.includes("image")) alert("이미지 파일만 업로드 가능합니다.");
                return false;
            });

            if(files.length > 5) {
                alert("이미지는 최대 5장까지 업로드 가능합니다.");
                return false;
            }
            return true;
        },
        close(index){
            this.form.files.splice(index, 1);
            this.previews.splice(index, 1);
        }
    },
    mounted(){
        this.init();
    }
};
</script>
